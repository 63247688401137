@import "~@angular/cdk/overlay-prebuilt.css";

@mixin letter-style {
  letter-spacing: var(--letter-spacing-2);
  font-style: var(--font-style-normal);
}

@mixin header-style {
  font-size: var(--font-size-26);
  line-height: var(--line-height-40);
}

@mixin subheading-style {
  font-size: var(--font-size-20);
  line-height: var(--line-height-32);
}

.header-h1 {
  font-family: var(--font-family-pt-serif-bold);
  @include header-style();
  @include letter-style();
}

.header-h2 {
  font-family: var(--font-family-roboto-bold);
  @include header-style();
  @include letter-style();
}

.sub-heading-s1 {
  font-family: var(--font-family-roboto-bold);
  @include subheading-style();
  @include letter-style();
}

.sub-heading-s2 {
  font-family: var(--font-family-roboto-regular);
  @include subheading-style();
  @include letter-style();
}

.section-title {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  @include letter-style();
}

.body-b1 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-16);
  line-height: var(--line-height-24);
  color: var(--font-color-secondary);
  @include letter-style();
}

.body-b2 {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  @include letter-style();
}

.body-b3 {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-14);
  line-height: var(--line-height-24);
  @include letter-style();
}

.labels {
  font-family: var(--font-family-roboto-bold);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  @include letter-style();
}

.caption {
  font-family: var(--font-family-roboto-regular);
  font-size: var(--font-size-12);
  line-height: var(--line-height-16);
  @include letter-style();
}

.df-c {
  display: flex;
  justify-content: center;
  align-items: center;
}

.resize-date-picker-overlay {
  width: auto !important;
}
.upperinput {
  input{
    text-transform: uppercase;
  }  
}
.horizontal-line {
  border-bottom: 1px solid var(--neutral-9);
}

