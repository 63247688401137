@use "mixin-breakpoints.scss" as *;

* {
  margin: 0;
  box-sizing: border-box;
}

/* Scroller CSS */

.scroll::-webkit-scrollbar {
  width: var(--spacing-8);
}

.scroll::-webkit-scrollbar-track {
  background-color: var(--scroll-background);
  border-width: 0px 0.5px;
  border-style: solid;
  border-color: var(--scroll-border);
}

.scroll::-webkit-scrollbar-thumb {
  border-right: 2px solid transparent;
  border-left: 2px solid transparent;
  background-clip: padding-box;
  border-radius: var(--border-radius-8);
  background-color: var(--scroll-thumb-background);

  &:hover {
    background-color: var(--scroll-thumb-hover);
  }
}

snl-page-tab{
  .page-tab{
    &:hover {background-color: inherit !important;}
  }
}

snl-toast { margin-top: 40px;margin-bottom: 20px;margin-right: 20px;}

.action-panel-main{
  snl-action-panel{
    box-shadow: none;
    padding: var(--spacing-0);
  }
  @include media-breakpoint-only(md) {
    display: none;
  }

}

snl-input{
  i{
    color: inherit !important;
  }
  &.search-input{
    min-width:280px;
    width:100%;
    @include media-breakpoint-only(sm) {
      width:100%;
      min-width:inherit;
      i{
        color: var(--neutral-6) !important;
      }
    }
    
  }

}
snl-table{
  .align-right{
    .heading-text,.data-text{justify-content: end !important;}
    .data-text:not(:empty) {
      max-width: inherit !important;
    }
    @include media-breakpoint-only(md) {
      .heading-text,.data-text{justify-content: start !important;}
    }
  }
  &.tableCard{
    .label, .d-none{display: none;}
    @include media-breakpoint-only(md) {
      .table-heading{display:none !important}
      .table__wrapper{
        display: flex;
        flex-direction: column;
        gap: 8px;
        snl-row {
          background: var(--neutral-12);
          display: flex;
          flex-direction: column;
          border: var(--spacing-1) solid var(--neutral-9);
          border-radius: var(--border-radius-4);
          padding: var(--spacing-16);
          gap: 16px;
          snl-td{
            &.td-none{
              display: none;
            }
            &.d-none{
              display: block;
            }
            border-bottom: 0px;
            .td__wrapper {
                .data-text:not(:empty){
                    max-width: 100% !important;
                    padding: var(--spacing-0) !important;
                }
            }
            .label-value{
              display: flex;
              flex-direction: column;
              gap: 4px;
              .label{
                  display: block;
                 
              }
              .value {
                font-family: var(--font-family-roboto-bold);
                font-size: var(--font-size-16);
                line-height: var(--line-height-24);
                letter-spacing: var(--letter-spacing-2);
                font-style: var(--font-style-normal);
                color: var( --font-color-primary);
              }
          }
          @include media-breakpoint-only(md) {
            .td__wrapper{
              .text-content{
                white-space: inherit !important;
              }
            }
          }
          
          }
        }
      }
    }
  }
}

.error{
  .container__dropdown-border:not(.container__dropdown-border--selected){
      border: var(--border-width-1) solid var(--semantic-9);
      .container__dropdown--label{
          color: var(--semantic-9);
          i{
              color: var(--semantic-9);
          }
      }
      i{
          color: var(--semantic-9);
      }
  }
  }

  .wrapper{
    .modal__wrapper-small {
      min-width: 700px;
    }
  }

  .accordion {
    .accordion__header {
      .accordion__icon--expanded{
          display: flex;
          align-items: center;
        }
    }
  }
